<template>
  <ul class="flex items-center space-x-4">
    <li>
      <ArchiveAssessmentButton
        :job="job"
        @close="$emit('job-updated')"
      />
    </li>
    <li>
      <EditJobButton
        :job="job"
        @close="$emit('job-updated')"
      />
    </li>
  </ul>
</template>

<script>
import EditJobButton from '@components/Jobs/EditJobButton'
import ArchiveAssessmentButton from '@components/ClientAssessments/ArchiveAssessmentButton'

export default {
  components: {
    EditJobButton,
    ArchiveAssessmentButton
  },

  props: {
    job: {
      type: Object,
      default: null
    }
  }
}
</script>
