<template>
  <div v-if="haveJobsLoaded">
    <BasePageHeader>
      <div>
        <BasePageBreadcrumbs :breadcrumbs="breadcrumbs"></BasePageBreadcrumbs>
        <BasePageHeading>Assessments</BasePageHeading>
      </div>
      <BasePageActions>
        <CreateAssessmentButton />
      </BasePageActions>
    </BasePageHeader>

    <BaseTabBar>
      <BaseTabs>
        <BaseTab
          :to="{ name: 'client-assessments-list' }"
          :active="!showArchived"
        >
          Active <span>({{ activeJobs.length.toLocaleString() }})</span>
        </BaseTab>
        <BaseTab
          :to="{ name: 'client-assessments-list', query: { show: 'archived' } }"
          :active="showArchived"
        >
          Archived <span>({{ archivedJobs.length.toLocaleString() }})</span>
        </BaseTab>
      </BaseTabs>
    </BaseTabBar>

    <BaseWrapper v-if="assessments.length > 0">
      <BaseTable>
        <BaseTHead>
          <tr>
            <BaseTHeadTh>
              Name
            </BaseTHeadTh>
            <BaseTHeadTh align="right">
              Candidates
            </BaseTHeadTh>
            <BaseTHeadTh align="right">
              Created
            </BaseTHeadTh>
            <BaseTHeadTh
              align="right"
              class="sr-only"
            >
              Actions
            </BaseTHeadTh>
          </tr>
        </BaseTHead>
        <BaseTBody>
          <tr
            v-for="assessment in assessments"
            :key="assessment.uuid"
          >
            <BaseTBodyTh width="30%">
              <router-link :to="{ name: 'client-assessments-show', params: { id: assessment.uuid } }">
                {{ assessment.name }}
              </router-link>
            </BaseTBodyTh>
            <BaseTBodyTd
              align="right"
              width="20%"
            >
              {{ assessment.candidatesCount }}
            </BaseTBodyTd>
            <BaseTBodyTd
              align="right"
              width="30%"
            >
              {{ formatDate(assessment.createdAt) }}
            </BaseTBodyTd>
            <BaseTBodyTd
              align="right"
              width="30%"
            >
              <div class="inline-flex space-x-2">
                <ArchiveAssessmentButton
                  v-if="!assessment.closedAt"
                  :job="assessment"
                />
                <UnarchiveAssessmentButton
                  v-else-if="assessment.closedAt"
                  :job="assessment"
                />
                <TooltipButton>
                  <template v-slot:content>
                    <BaseButton
                      variant="salmon"
                      size="small"
                      :to="{ name: 'client-assessments-show', params: { id: assessment.uuid } }"
                    >
                      <template slot="iconMiddle">
                        <Icon
                          view-box="0 0 24 24"
                          class="w-5 h-5"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                        >
                          <Users fill="none" />
                        </Icon>
                      </template>
                    </BaseButton>
                  </template>
                  <template v-slot:tooltip>
                    View candidates
                  </template>
                </TooltipButton>
              </div>
            </BaseTBodyTd>
          </tr>
        </BaseTBody>
      </BaseTable>
    </BaseWrapper>
    <BaseWrapper v-else>
      <JobsEmpty />
    </BaseWrapper>
  </div>
  <div v-else>
    <BasePageHeader>
      <div>
        <BasePageBreadcrumbs></BasePageBreadcrumbs>
        <BasePageHeading></BasePageHeading>
      </div>
      <CreateAssessmentButton />
    </BasePageHeader>
    <Loader />
  </div>
</template>

<script>
// Components
import ArchiveAssessmentButton from '@components/ClientAssessments/ArchiveAssessmentButton'
import CreateAssessmentButton from '@components/ClientAssessments/CreateAssessmentButton'
import JobsEmpty from '@components/Jobs/JobsEmpty'
import Loader from '@components/Loader'
import UnarchiveAssessmentButton from '@components/ClientAssessments/UnarchiveAssessmentButton'
import Icon from '@components/Icons/Icon'
import Users from '@components/Icons/Users'
import TooltipButton from '@components/TooltipButton'

// Utilites
import { mapGetters } from 'vuex'
import { formatDate } from '@utils/formatDate'

export default {
  components: {
    ArchiveAssessmentButton,
    CreateAssessmentButton,
    JobsEmpty,
    Loader,
    UnarchiveAssessmentButton,
    Icon,
    Users,
    TooltipButton
  },

  data() {
    return {
      formatDate
    }
  },

  computed: {
    ...mapGetters({
      haveJobsLoaded: 'jobs/haveJobsLoaded',
      activeJobs: 'jobs/activeJobs',
      archivedJobs: 'jobs/archivedJobs',
      organisationName: 'organisations/name'
    }),

    /**
     * @return {Array}
     */
    breadcrumbs() {
      return [
        {
          name: this.organisationName
        }
      ]
    },

    /**
     * @return {Boolean}
     */
    showArchived() {
      return this.$route.query.show === 'archived'
    },

    /**
     * @return {Array}
     */
    assessments() {
      if (this.showArchived) {
        return this.archivedJobs
      }
      return this.activeJobs
    }
  },

  created() {
    if (!this.haveJobsLoaded) {
      this.$store.dispatch('jobs/getJobs')
    }
  }
}
</script>
