<template>
  <div class="bg-white w-full py-15">
    <div class="px-20 ">
      <h2 class="text-3xl">
        Testing starts with an assessment
      </h2>
      <p class="text-gray-600 mb-6 text-xl">
        Create an assessment, choose your tests, and then send to your candidates
      </p>
    </div>
    <div class="grid grid-cols-2">
      <div class="px-20">
        <div class="mt-10 max-w-xs">
          <MultiTasking />
        </div>
      </div>
      <div class="px-20">
        <p class="text-gray-600 mb-6 text-md">
          You have one free assessment to try out {{ satelliteName }},
          then you can upgrade to a plan and use as many as you need.
        </p>
        <div class="mt-10">
          <CreateAssessmentButton size="large" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MultiTasking from '@components/Vectors/MultiTasking'
import CreateAssessmentButton from '@components/ClientAssessments/CreateAssessmentButton'

export default {
  components: {
    MultiTasking,
    CreateAssessmentButton
  },

  data() {
    return {
      satelliteName: process.env.VUE_APP_SATELLITE_NAME
    }
  }
}
</script>
